


































import { Component, Vue, Prop } from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';
import Accordion from '@/components/Accordion/index.vue';

@Component({
  name: 'TopicsTree',
  components: {
    Accordion,
    Checkbox
  }
})
export default class AccordionTopics extends Vue {
  @Prop() discipline!: Record<string, any>;
  @Prop() indexDiscipline!: number;
  @Prop({ default: '10px' }) padding!: string;

  setCheckbox(name: string, checked: boolean, indexTopic: number) {
    this.$emit('checked-topic', name, checked, indexTopic, this.indexDiscipline);
  }
}
