
































import { Component, Prop, Vue } from 'vue-property-decorator';

const ICON_COLOR_BOX = 'var(--primarycolor-dark)';

@Component
export default class ScheduleTable extends Vue {
  // @Prop() setTableOptions!: Function;
  @Prop() index!: number;

  private ICON_COLOR_BOX = ICON_COLOR_BOX;
  private weekDays = ['Dom.', 'Seg.', 'Ter.', 'Quar.', 'Quin.', 'Sex.', 'Sáb.'];
  private timeList = [
    '05h às 06h',
    '06h às 07h',
    '07h às 08h',
    '08h às 09h',
    '09h às 10h',
    '10h às 11h',
    '11h às 12h',
    '12h às 13h',
    '13h às 14h',
    '14h às 15h',
    '15h às 16h',
    '16h às 17h',
    '17h às 18h',
    '18h às 19h',
    '19h às 20h',
    '20h às 21h',
    '21h às 22h',
    '22h às 23h',
    '23h às 00h'
  ];
  private matrix: Array<Array<Record<string, any>>> = [];

  created() {
    this.createMatrix();
  }

  createMatrix() {
    let row: Array<Record<string, any>> = [];
    this.timeList.forEach(() => {
      this.weekDays.forEach(() => {
        row.push({ select: false });
      });
      this.matrix.push(row);
      row = [];
    });
  }

  setDayTime(dayIndex: number, timeIndex: number) {
    this.matrix[dayIndex][timeIndex].select = !this.matrix[dayIndex][timeIndex].select;
    this.$emit('table-options', this.matrix, this.index);
    // this.setTableOptions(this.matrix, this.index);
  }

  getDayTime(dayIndex: number, timeIndex: number) {
    return this.matrix[dayIndex][timeIndex].select;
  }
}
