







































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Modal,
    Checkbox
  }
})
export default class ModalHistoric extends Vue {
  private checked = false;

  setCheckbox(type: string, checked: boolean) {
    this.checked = !checked;
  }

  handleNext() {
    this.$emit('delete-historic', 1, 7);
  }
}
