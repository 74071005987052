











































import { Component, Vue, Watch } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading
  }
})
export default class OptionQuestion extends Vue {
  private validatePlanner: boolean | null = null;

  mounted() {
    this.verifyPlanner();
  }

  @Watch('$store.getters.plannerInteraction', {
    deep: true
  })
  setValidatePlanner() {
    const storeLoadingValidate = this.$store.getters.plannerInteraction.loadingValidate;
    this.validatePlanner = storeLoadingValidate;
  }

  handleRemakeTime() {
    this.$emit('remake-time');
  }

  handleRemakeContent() {
    this.$emit('remake-content');
  }

  @Watch('$store.getters.plannerResponse', {
    deep: true
  })
  verifyPlanner() {
    const storeVerify = this.$store.getters.plannerVerifyPlanner;

    if (storeVerify) {
      this.$store.commit('setPlannerLoadingValidate', true);
      this.$store.commit('setPlannerValidatePlanner', true);
      return;
    }

    this.$store.commit('setPlannerLoadingValidate', false);
    this.$store.commit('setPlannerValidatePlanner', false);
  }
}
