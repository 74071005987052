








































import { Component, Vue, Prop } from 'vue-property-decorator';
import iArrow from '@/assets/icons/Arrow.vue';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

@Component({
  components: {
    iArrow
  },
  filters: {
    setNumber
  }
})
export default class WrapperQuestion extends Vue {
  @Prop() totalQuestion!: number;
  @Prop() isCanEnabled!: boolean;
  @Prop() nameID!: string;
  @Prop() isResponse!: boolean;
  @Prop() currentQuestion!: number;
  @Prop() index!: number;
  @Prop() ableButton!: boolean;
  @Prop() noShowFooter!: boolean;

  private numberCurrentQuestion = this.index + 1;
  private first = this.index === 0;
  private last = this.index === this.totalQuestion - 1;

  showButton() {
    if (this.currentQuestion === 8) return true;
    if (this.ableButton && this.currentQuestion === 1) return true;
    return false;
  }

  handleNavigationLeft() {
    this.$emit('navigation-left', this.index);
  }

  handleNavigationRight() {
    this.$emit('navigation-right', this.index);
  }

  handleValidate() {
    this.$emit('validate-planner', this.index);
  }
}
