

























































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';
import Calendar from '@/assets/icons/Calendar.vue';

const ICON_COLOR_DISABLED = 'var(--neutralcolor-high-dark)';

@Component({
  components: {
    Calendar
  }
})
export default class InputCalendar extends Vue {
  @Prop() mainIndex!: number;
  // @Prop() setDateOption!: Function;

  private isDisabled = true;
  private ICON_COLOR_DISABLED = ICON_COLOR_DISABLED;

  private date = {
    start: '',
    end: ''
  };

  @Watch('date.start')
  setDisabled() {
    if (this.date.start && !this.date.end) {
      this.isDisabled = false;
      this.date.end = '';
    } else {
      this.date.end = '';
      this.isDisabled = true;
    }
  }

  // mounted() {
  //   this.setDateOption(this.date, this.mainIndex);
  // }

  @Watch('date.start')
  @Watch('date.end')
  setDate() {
    this.$emit('date-option', this.date, this.mainIndex);
    // this.setDateOption(this.date, this.mainIndex);
  }
}
