





















































































































































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Book from '@/components/Book/index.vue';

@Component({
  components: {
    ContainerSidebar,
    ContainerFluid,
    Book
  }
})
export default class PlannerSidebar extends Vue {
  @Prop() totalDaysPlanner!: number;
  @Prop() totalWeekPlanner!: number;

  @Prop() methodExercise!: string;
  @Prop() nameFocus!: string;
  @Prop() series!: string;
  @Prop() language!: string;
  @Prop() withRedaction!: string;
  @Prop() withHistoric!: string;
  @Prop() disciplinesExclude!: number;
  @Prop() topicsExclude!: number;

  showSummaryContent() {
    if (this.methodExercise || this.nameFocus || this.series || this.language || this.withRedaction || this.withHistoric || this.disciplinesExclude || this.topicsExclude) {
      return true;
    }
    return false;
  }

  modifierNumber(value: number) {
    return value < 10 ? `0${value}` : String(value);
  }
}
