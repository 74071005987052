































































import { Component, Vue } from 'vue-property-decorator';
import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Checkbox
  }
})
export default class InstructionUser extends Vue {
  private checked = false;

  setCheckbox(type: string, checked: boolean) {
    this.checked = !checked;
  }

  handleBackDasboard() {
    this.$router.push({ path: '/dashboard' });
  }

  handleConfiguration() {
    this.$emit('continue-setup');
  }
}
